.headshotDiv {
    margin-top: 8.2vw;
    margin-left: 5vw;
    margin-right: 5vw;
    width: 41%;
}

.headshot {
    background-color: #efefef;
    width: 100%;
    border-radius: 2vw;
    box-shadow: rgba(239, 239, 239, 0.2) 0.5vw 0.5vw 2vw, rgba(239, 239, 239, 0.2) -0.5vw -0.5vw 2vw, rgba(239, 239, 239, 0.2) 0.5vw -0.5vw 2vw,rgba(239, 239, 239, 0.2) -0.5vw 0.5vw 2vw;
}

.headshotMobile {
    background-color: #efefef;
    border-radius: 2vw;
    width: 60%;
    box-shadow: rgba(239, 239, 239, 0.2) 0.5vw 0.5vw 2vw, rgba(239, 239, 239, 0.2) -0.5vw -0.5vw 2vw, rgba(239, 239, 239, 0.2) 0.5vw -0.5vw 2vw,rgba(239, 239, 239, 0.2) -0.5vw 0.5vw 2vw;
}

.headshotTitle { 
    color: #efefef;
    position: absolute;
    font-family: "SoftTechno";
    font-size: 7vw;
    margin-top: 0;
    margin-bottom: 0;
}

.headshotTitleMobile { 
    color: #efefef;
    position: absolute;
    font-family: "SoftTechno";
    font-size: 9.5vw;
    margin-top: 0;
    margin-bottom: 0;
}

/* 4 engineering classnames, various mobile and active states */
.engineerTitle {
    margin-left: 1.6vw;
    margin-top: -1.4vw;
    filter: drop-shadow(0.2vw 0.2vw #C32376);
    text-shadow: 1px 1px black, -1px 1px black, 1px -1px black, -1px -1px black, 3px 3px black;
    color: #efefef;
    transition: color 1s linear, text-shadow 1s linear;
}

.activeEngineerTitle {
    margin-left: 1.6vw;
    margin-top: -1.4vw;
    color: #C32376;
    filter: drop-shadow(0.2vw 0.2vw black);
    text-shadow: 1px 1px #efefef, -1px 1px #efefef, 1px -1px #efefef, -1px -1px #efefef, 3px 3px #efefef;
    transition: color 1s linear, text-shadow 1s linear;
}

.engineerTitleMobile {
    margin-left: 22vw;
    margin-top: -1.4vw;
    filter: drop-shadow(0.4vw 0.4vw #C32376);
    text-shadow: 1px 1px black, -1px 1px black, 1px -1px black, -1px -1px black, 3px 3px black;
    color: #efefef;
    transition: color 1s linear, text-shadow 1s linear;
}

.activeEngineerTitleMobile {
    margin-left: 22vw;
    margin-top: -1.4vw;
    color: #C32376;
    filter: drop-shadow(0.2vw 0.2vw black);
    text-shadow: 1px 1px #efefef, -1px 1px #efefef, 1px -1px #efefef, -1px -1px #efefef, 3px 3px #efefef;
    transition: color 1s linear, text-shadow 1s linear;
}

/* 4 designer classnames, various mobile and active states */
.designerTitle {
    margin-left: 35.6vw;
    margin-top: 7.5vw;
    writing-mode: vertical-lr;
    filter: drop-shadow(-0.2vw 0.2vw #ffc6d3);
    text-shadow: 1px 1px black, -1px 1px black, 1px -1px black, -1px -1px black, 3px 3px black;
    color: #efefef;
    transition: color 1s linear, text-shadow 1s linear;
}

.activeDesignerTitle {
    margin-left: 35.6vw;
    margin-top: 7.5vw;
    color: #ffc6d3;
    writing-mode: vertical-lr;
    filter: drop-shadow(-0.2vw 0.2vw black);
    text-shadow: 1px 1px black, -1px 1px black, 1px -1px black, -1px -1px black, 3px 3px #efefef;
    transition: color 1s linear, text-shadow 1s linear;
}

.designerTitleMobile {
    margin-left: 72.5vw;
    margin-top: 11vw;
    writing-mode: vertical-lr;
    filter: drop-shadow(-0.4vw 0.4vw #ffc6d3);
    text-shadow: 1px 1px black, -1px 1px black, 1px -1px black, -1px -1px black, 3px 3px black;
    color: #efefef;
    transition: color 1s linear, text-shadow 1s linear;
}

.activeDesignerTitleMobile {
    margin-left: 72.5vw;
    margin-top: 11vw;
    color: #ffc6d3;
    writing-mode: vertical-lr;
    filter: drop-shadow(-0.2vw 0.2vw black);
    text-shadow: 1px 1px black, -1px 1px black, 1px -1px black, -1px -1px black, 3px 3px #efefef;
    transition: color 1s linear, text-shadow 1s linear;
}

/* 4 entrepreneur classnames, various mobile and active states */
.entrepreneurTitle {
    margin-left: 9.8vw;
    margin-top: 42.2vw;
    filter: drop-shadow(0.2vw 0.2vw #df46b7);
    text-shadow: 1px 1px black, -1px 1px black, 1px -1px black, -1px -1px black, 3px 3px black;
    color: #efefef;
    transition: color 1s linear, text-shadow 1s linear;
}

.activeEntrepreneurTitle {
    margin-left: 9.8vw;
    margin-top: 42.2vw;
    color: #df46b7;
    filter: drop-shadow(0.2vw 0.2vw black);
    text-shadow: 1px 1px #efefef, -1px 1px #efefef, 1px -1px #efefef, -1px -1px #efefef, 3px 3px #efefef;
    transition: color 1s linear, text-shadow 1s linear;
}

.entrepreneurTitleMobile {
    margin-left: 35vw;
    margin-top: 62.2vw;
    filter: drop-shadow(0.4vw 0.4vw #df46b7);
    text-shadow: 1px 1px black, -1px 1px black, 1px -1px black, -1px -1px black, 3px 3px black;
    color: #efefef;
    transition: color 1s linear, text-shadow 1s linear;
}

.activeEntrepreneurTitleMobile {
    margin-left: 35vw;
    margin-top: 62.2vw;
    color: #df46b7;
    filter: drop-shadow(0.2vw 0.2vw black);
    text-shadow: 1px 1px #efefef, -1px 1px #efefef, 1px -1px #efefef, -1px -1px #efefef, 3px 3px #efefef;
    transition: color 1s linear, text-shadow 1s linear;
}