.innerText {
    position: absolute;
    min-height: 20vw;
    max-height: 20vw;
    min-width: 20vw;
    max-width: 20vw;
    margin-left: 9.15vw;
    margin-top: 9vw;
    border-radius: 50%;
    padding: 2vw;
    /* animation: innerTextRotation 10s infinite linear;
    transition: rotate 1s ease-out; */
    animation: innerTextRotation 15s linear infinite;
    transition: 3s ease-out;
    background-image: linear-gradient(#C32376, black);
    z-index: 10;
}

.innerText:hover {
    animation-play-state: paused;
    transform: rotate(-.1turn);
    box-shadow: inset 0 0 0 100vmax rgba(0, 0, 0, 1);
}

@keyframes innerTextRotation {
    to {rotate: -1turn}
}