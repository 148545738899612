.title {
    color: #ebebeb;
    font-size: 20vw;
    font-family: SoftTechno;
    padding-top: 0px;
    padding-bottom: 2vw;
    margin-top: 0px;
    font-synthesis: none;
    margin-bottom: -40px;
    margin-left: 2vw;
}

.titleMobile {
    color: #ebebeb;
    font-size: 20vw;
    font-family: SoftTechno;
    padding-top: 5px;
    padding-bottom: 5px;
    margin-top: 0px;
    margin-bottom: 0px;
    font-synthesis: none;
}

.row {
    display: flex;
  }