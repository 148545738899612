.myWork {
    font-size: 14vw;
    font-family: SoftTechno;
    font-synthesis: none;
    margin-top: 1.5vw;
    margin-bottom: 0.5vw;
    color: #ebebeb;
    margin-left: 2vw;
}

.myWorkMobile {
    font-size: 20vw;
    font-family: SoftTechno;
    font-synthesis: none;
    margin-top: 0vw;
    margin-bottom: -5vw;
    color: #ebebeb;
}

.myWorkDiv {
    padding-top: 2vw;
    display: flex;
    margin-top: 0vw;
}

.tags {
    display: flex;
    flex-wrap: wrap;
    width: 74%;

    text-align: center;
    align-items: center;
    justify-content: center;

    margin-top: 0vw;
    margin-bottom: 4vw;
    padding-left: 1vw;
    margin-left: 13%;
}

.tagsMobile {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    
    margin-top: 3.6vw;
    margin-bottom: 3vw;
    padding-left: 1vw;
    margin-left: 5.6vw;
}

.tagButton {
    align-items: center;

    margin-left: 0.5vw;
    margin-right: 0.5vw;
}

.tagButtonBottomRow {
    align-items: center;

    margin-left: 0.5vw;
    margin-right: 0.5vw;
    margin-top: 0vw;
}

/* mobile tag buttons */
.tagButtonMobile {
    align-items: center;
    margin-left: 0.5vw;
    margin-right: 0.5vw;
}

.tagButtonBottomRowMobile {
    align-items: center;

    margin-left: 1vw;
    margin-right: 0.5vw;
    margin-top: 0.8vw;
}

.tagText {
    width: fit-content;

    white-space: nowrap;
    font-size: 6.5vw;
    font-family: 'ScrawlStorm';
    color: #ebebeb;
    text-shadow: black 0.1vw 0.1vw, #C32376 4px 4px 6px, #C32376 -4px -4px 6px, #C32376 4px -4px 6px,#C32376 -4px 4px 6px;
    
    background-color: transparent;
    border-radius: 10px;
    border: none;

    margin-top: 0vw;
    margin-bottom: 0vw;
    margin-left: 1vw;
    margin-right: 1vw;
    padding-inline-end: 0vw;
    padding-inline-start: 0vw;
}

.tagTextActive {
    width: fit-content;

    white-space: nowrap;
    font-size: 6.5vw;
    font-family: 'ScrawlStorm';
    text-decoration: underline;
    color: #ebebeb;
    text-shadow: black 0.1vw 0.1vw, #ffc6d3 4px 4px 16px, #ffc6d3 -4px -4px 16px, #ffc6d3 4px -4px 16px,#ffc6d3 -4px 4px 16px;

    background-color: transparent;
    border-radius: 10px;
    border: none;

    margin-top: 0vw;
    margin-bottom: 0vw;
    margin-left: 1vw;
    margin-right: 1vw;
    padding-inline-end: 0vw;
    padding-inline-start: 0vw;
}


.tagTextMobile {
    white-space: nowrap;
    font-size: 8.5vw;
    font-family: 'ScrawlStorm';
    color: #ebebeb;
    margin-top: 0vw;
    margin-bottom: 0vw;
    text-shadow: black 0.1vw 0.1vw, #C32376 4px 4px 6px, #C32376 -4px -4px 6px, #C32376 4px -4px 6px,#C32376 -4px 4px 6px;
    background-color: transparent;
    border-radius: 10px;
    border: none;
}

.tagTextActiveMobile {
    white-space: nowrap;
    font-size: 8.5vw;
    font-family: 'ScrawlStorm';
    text-decoration: underline;
    color: #ebebeb;
    margin-top: 0vw;
    margin-bottom: 0vw;
    text-shadow: black 0.1vw 0.1vw, #ffc6d3 4px 4px 16px, #ffc6d3 -4px -4px 16px, #ffc6d3 4px -4px 16px,#ffc6d3 -4px 4px 16px;
    background-color: transparent;
    border-radius: 10px;
    border: none;
}

.tagText:hover {
    box-shadow: 0px 0px;
}

.tagTextActive:hover {
    box-shadow: 0px 0px;
}

.upperLine {
    border: #efefef solid 0.1vw;
    margin-left: 1vw;
}

.highlightsImage {
    height: 7.6vw;
    margin-top: 4.7vw;
    margin-bottom: 0.5vw;
    margin-left: 1vw;
    margin-right: 1vw;
}

.highlightsImageMobile {
    height: 15.6vw;
    margin-top: 4.7vw;
    margin-bottom: 0.5vw;
    margin-left: 0.5vw;
    margin-right: 0.5vw;
}

.highlightsImageTaller {
    height: 10.6vw;
    margin-top: 3.2vw;
    margin-bottom: 0.5vw;
    margin-left: 0.5vw;
    margin-right: 1vw;
}

.oofImage {
    height: 9vw;
    margin-top: 5.2vw;
    margin-left: 2vw;
}

.oofImageMobile {
    height: 13vw;
    margin-top: 5vw;
    margin-left: 2.5vw;
}



.firstImage {
    margin-left: 10vw;
}

.firstImageMobile {
    margin-left: 2vw;
}

.buttonRow {
    display: flex;
    justify-content: center;
}

.projectButton {
    margin: 0 1vw 2vw 1vw;

    padding: 0 1vw 0 1vw;
    border: 0.5vw solid white;

    color: white;
    font-size: 6vw;
    font-family: SoftTechno;
}

.link {
    text-decoration: none;
}