.careerText {
    margin: 0.75vw 0 0 0;

    text-align: center;
    font-family: "CierraText";
    color: black;
    font-size: 2.8vw;
    font-weight: 900;
}

.largeCircleText {
    margin: 3vw 2vw 0 2vw;
    font-size: 2.2vw;
}

.mediumCircleText {
    margin: 2vw 2vw 0 2vw;
    font-size: 2.2vw;
}

.mediumCircleTextTall {
    margin: 0.5vw 2vw 0 2vw;
    font-size: 2vw;
}

.mediumCircleTextbbg {
    margin: 2vw 0vw 0 0vw;
    font-size: 2.2vw;
}

.largestCircleText {
    margin: 6vw 2vw 0 2vw;
    font-size: 3.5vw;
}

.smallCircleText {
    margin: 1vw 2vw 0 2vw;

    font-size: 1.75vw;
}

.smallCircleShortText {
    margin: 2vw 2vw 0 2vw;

    font-size: 1.75vw;
}

.smallestCircleShortText {
    margin: 1.8vw 2vw 0 2vw;

    font-size: 1.2vw;
}

.smallCareerCircle {
    position: absolute;

    width: 10vw;
    height: 10vw;
    border-radius: 40vw;
    border: 0.35vw solid #ebebeb;
}

.mediumCareerCircle {
    position: absolute;

    width: 12.5vw;
    height: 12.5vw;
    border-radius: 40vw;
    border: 0.35vw solid #ebebeb;
}

.largeCareerCircle {
    position: absolute;

    width: 15vw;
    height: 15vw;
    border-radius: 40vw;
    border: 0.35vw solid #ebebeb;
}

.largestCareerCircle {
    position: absolute;

    width: 25vw;
    height: 25vw;
    border-radius: 40vw;
    border: 0.35vw solid #ebebeb;
}

.topRectangle {
    position: absolute;
    left: 15vw;
    top: 48vw;

    background-color: #C32376;

    width: 73vw;
    height: 5vw;
    border: 0.35vw solid #ebebeb;
}

.gsCircle {
    left: 46vw;
    top: 55vw;

    background-color: #C32376;
}

.graphicDesignCircle {
    left: 24vw;
    top: 58vw;

    background-color: #ffc6d3;
}

.appDevCircle {
    left: 72vw;
    top: 58vw;

    background-color: #C32376;
}

.productCircle {
    left: 18vw;
    top: 70vw;

    background-color: #C32376;
}

.datedCircle {
    left: 36vw;
    top: 70vw;

    background: rgb(255,198,211);
    background: radial-gradient(circle, rgba(255,198,211,1) 6%, rgba(223,70,183,1) 53%, rgba(195,35,118,1) 78%);    
}

.ceriseCircle {
    left: 56vw;
    top: 70vw;
    
    background: rgb(223,70,183);
    background: radial-gradient(circle, rgba(223,70,183,1) 0%, rgba(195,35,118,1) 100%);
}

.bbgCircle {
    left: 24vw;
    top: 80vw;

    background-color: #C32376;
}

.goodseedCircle {
    left: 39vw;
    top: 90vw;

    background-color: #df46b7;
}

.wsfCircle {
    left: 52vw;
    top: 96vw;

    background: rgb(223,70,183);
    background: radial-gradient(circle, rgba(223,70,183,1) 0%, rgba(195,35,118,1) 100%);
}

.infCircle {
    left: 69vw;
    top: 100vw;

    background: rgb(223,70,183);
    background: radial-gradient(circle, rgba(223,70,183,1) 0%, rgba(255,198,211,1) 100%);
}

.emblCircle {
    left: 35vw;
    top: 105vw;

    background-color: #ffc6d3;
}

.byteCircle {
    left: 50vw;
    top: 110vw;

    background: rgb(255,198,211);
    background: radial-gradient(circle, rgba(255,198,211,1) 6%, rgba(223,70,183,1) 53%, rgba(195,35,118,1) 78%);
}

.oofCircle {
    left: 20vw;
    top: 96vw;

    background: rgb(255,198,211);
    background: radial-gradient(circle, rgba(255,198,211,1) 6%, rgba(223,70,183,1) 53%, rgba(195,35,118,1) 78%);
}

.noodlepediaCircle {
    left: 70vw;
    top: 120vw;

    background: rgb(255,198,211);
    background: radial-gradient(circle, rgba(255,198,211,1) 6%, rgba(223,70,183,1) 53%, rgba(195,35,118,1) 78%);
}