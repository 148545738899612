.AppDesktop {
  text-align: left;
  max-width: 100%;
  overflow-x: hidden;
}

.AppMobile {
  text-align: center;
  max-width: 100%;
  overflow-x: hidden;
}

.blurImageDesktop {
  position: fixed;
  left: 0;
  min-width: 100%;
  max-width: 100%;
  z-index: -10;
}

.blurImageMobile {
  opacity: 60%;
  position: fixed;
  left: 0;
  min-width: 100vw;
  max-width: 100vw;
  z-index: -10;
}