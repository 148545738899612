.learnMore {
    display: flex;
    position: relative;
    border: 0.5vw solid #efefef;
    border-radius: 5vw;
    min-height: 11.5vw;
    max-height: 11.5vw;
    min-width: 9vw;
    max-width: 9vw;
    margin-top: 2vw;
    margin-right: 1vw;
    margin-left: 0.5vw;
    float: right;
    box-shadow: inset 0 0 0 100vmax rgba(0, 0, 0, 1);
    background-image: linear-gradient(#C32376, #ffc6d3);
    transition: box-shadow 0.5s linear;
}

.learnMore:hover
{
    box-shadow: none;
    background-image: linear-gradient(#C32376, #ffc6d3);
}

.learnMoreMobile {
    display: flex;
    position: relative;
    border: 0.5vw solid #efefef;
    border-radius: 5vw;
    min-height: 16vw;
    max-height: 16vw;
    min-width: 9vw;
    max-width: 9vw;
    margin-top: 4vw;
    margin-right: 1vw;
    float: right;
    box-shadow: inset 0 0 0 100vmax rgba(0, 0, 0, 1);
    background-image: linear-gradient(#C32376, #ffc6d3);
    transition: box-shadow 0.5s linear;
}

.learnMoreMobile:hover
{
    box-shadow: none;
    background-image: linear-gradient(#C32376, #ffc6d3);
}

.learnMoreText {
    position: absolute;
    font-family: "SoftTechno";
    font-size: 8vw;
    margin-top: 1.6vw;
    margin-left: 2vw;
    margin-bottom: 0;
    line-height: 2vw;
    text-align: left;
}

.learnMoreTextMobile {
    position: absolute;
    font-family: "SoftTechno";
    font-size: 10vw;
    margin-top: 2vw;
    margin-left: 0vw;
    margin-bottom: 4.6vw;
    line-height: 4vw;
    text-align: center;
}


.arrow {
    width: 3vw;
    height: 1vw;
    margin-top: 8.6vw;
    margin-left: 3vw;
}

.arrowMobile {
    width: 4vw;
    height: 2vw;
    margin-top: 12.6vw;
    margin-left: 2.8vw;
    margin-bottom: 4.6vw;
}

.learnMoreTag {
    color: #efefef;
}