.drawingMobile {
    align-items: center;
    display: flex;
    min-height: 87vw;
    max-height: 87vw;
    margin-left: 7.5vw;
    margin-top: 3vw;
    border-radius: 50vw;
}

.quadDesignMobile {
    min-height: 89vw;
    max-height: 89vw;
    min-width: 89vw;
    max-width: 89vw;
    margin-left: -3vw;
    margin-top: 0vw;
    position: absolute;
    z-index: 5;
}

.quadDesignColorMobile {
    min-height: 89vw;
    max-height: 89vw;
    min-width: 89vw;
    max-width: 89vw;
    margin-left: -3vw;
    margin-top: 0vw;
    position: absolute;
    z-index: 4;
}


.binaryImageMobile {
    min-height: 84vw;
    max-height: 84vw;
    min-width: 84vw;
    max-width: 84vw;
    border-radius: 50vw;
    position: absolute;
    animation: rotation 60s infinite linear;
}

@keyframes rotation {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(359deg);
    }
}

.edgeCoversMobile {
    min-height: 83.6vw;
    max-height: 83.6vw;
    min-width: 83.6vw;
    max-width: 83.6vw;
    position: absolute;
    margin-left: 0.08vw;
    margin-top: 0.155vw;
}

/* 3 classes for transitioning to life */
.edgeEngToLife {
    animation: rotationEngToLife  1s 1 linear;
    transform: rotate(0deg);
}
@keyframes rotationEngToLife  {
    from {
        transform: rotate(179deg);
    }

    to {
        transform: rotate(0deg);
    }
}

.edgeDesToLife {
    animation: rotationDesToLife  1s 1 linear;
    transform: rotate(0deg);
}
@keyframes rotationDesToLife  {
    from {
        transform: rotate(270deg);
    }

    to {
        transform: rotate(0deg);
    }
}

.edgeEntrepToLife {
    animation: rotationEntrepToLife  1s 1 linear;
    transform: rotate(0deg);
}
@keyframes rotationEntrepToLife  {
    from {
        transform: rotate(89deg);
    }

    to {
        transform: rotate(-0eg);
    }
}

/* 3 classes for transitioning to Des */
.edgeEngToDes {
    animation: rotationEngToDes  1s 1 linear;
    transform: rotate(-90deg);
}
@keyframes rotationEngToDes  {
    from {
        transform: rotate(179deg);
    }

    to {
        transform: rotate(-90deg);
    }
}

.edgeLifeToDes {
    animation: rotationLifeToDes  1s 1 linear;
    transform: rotate(-90deg);
}
@keyframes rotationLifeToDes  {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(-90deg);
    }
}

.edgeEntrepToDes {
    animation: rotationEntrepToDes  1s 1 linear;
    transform: rotate(-90deg);
}
@keyframes rotationEntrepToDes  {
    from {
        transform: rotate(89deg);
    }

    to {
        transform: rotate(-90deg);
    }
}

/* 3 classes for transitioning to Eng */
.edgeLifeToEng {
    animation: rotationLifeToEng  1s 1 linear;
    transform: rotate(-179deg);
}
@keyframes rotationLifeToEng  {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(-179deg);
    }
}

.edgeDesToEng {
    animation: rotationDesToEng  1s 1 linear;
    transform: rotate(-179deg);
}
@keyframes rotationDesToEng  {
    from {
        transform: rotate(-89deg);
    }

    to {
        transform: rotate(-179deg);
    }
}

.edgeEntrepToEng {
    animation: rotationEntrepToEng  1s 1 linear;
    transform: rotate(-179deg);
}
@keyframes rotationEntrepToEng  {
    from {
        transform: rotate(89deg);
    }

    to {
        transform: rotate(-179deg);
    }
}

/* 3 classes for transitioning to Entrep */
.edgeEngToEntrep {
    animation: rotationEngToEntrep  1s 1 linear;
    transform: rotate(-269deg);
}
@keyframes rotationEngToEntrep  {
    from {
        transform: rotate(-179deg);
    }

    to {
        transform: rotate(-269deg);
    }
}

.edgeDesToEntrep {
    animation: rotationDesToEntrep  1s 1 linear;
    transform: rotate(-269deg);
}
@keyframes rotationDesToEntrep  {
    from {
        transform: rotate(-89deg);
    }

    to {
        transform: rotate(-269deg);
    }
}

.edgeLifeToEntrep {
    animation: rotationLifeToEntrep  1s 1 linear;
    transform: rotate(-269deg);
}
@keyframes rotationLifeToEntrep  {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(-269deg);
    }
}

.innerCircleButtonMobile {
    min-height: 7vw;
    max-height: 7vw;
    min-width: 7vw;
    max-width: 7vw;
    margin-top: 1.55vw;
    margin-left: 1.555vw;
    border-radius: 50%;
    position: absolute;
    transition: all 2s;
    z-index: 15;
}

.innerCircleButtonActiveMobile {
    min-height: 10vw;
    max-height: 10vw;
    min-width: 10vw;
    max-width: 10vw;
    margin-top: 2vw;
    margin-left: 2vw;
    border-radius: 50%;
    position: absolute;
    transition: all 2s;
    z-index: 15;
}

.circleButtonMobile {
    border: solid #ebebeb 2px;
    min-height: 10vw;
    max-height: 10vw;
    min-width: 10vw;
    max-width: 10vw;
    border-radius: 50%;
    position: absolute;
    transition: all 2s;
    z-index: 15;
}

.circleButtonActiveMobile {
    border: solid black 2px;
    background-color: #efefef;
    min-height: 14vw;
    max-height: 14vw;
    min-width: 14vw;
    max-width: 14vw;
    border-radius: 50%;
    position: absolute;
    transition: all 2s;
    z-index: 15;
}

.innerCircleButton__leftMobile {
    position: absolute;
    background-color: #C32376;
}

.innerCircleButton__rightMobile {
    position: absolute;
    background-color: black;
}

.innerCircleButton__topMobile {
    position: absolute;
    background-color: #ffc6d3;
}

.innerCircleButton__bottomMobile {
    position: absolute;
    background-color: #df46b7;
}

.circleButton__leftMobile {
    position: absolute;
    transition: all 2s;
    margin-left: 4vw;
    margin-top: 0vw;
}

.circleButton__rightMobile {
    position: absolute;
    transition: all 2s;
    margin-left: 69vw;
    margin-top: -1vw;
}

.circleButton__topMobile {
    position: absolute;
    transition: all 2s;
    margin-top: -65vw;
    margin-left: 36.5vw;
}

.circleButton__bottomMobile {
    position: absolute;
    transition: all 2s;
    margin-top: 65vw;
    margin-left: 36.5vw;
}

.circleButton__rightActiveMobile {
    position: absolute;
    transition: all 2s;
    margin-left: 64vw;
    margin-top: -2vw;
}

.circleButton__leftActiveMobile {
    position: absolute;
    transition: all 2s;
    margin-left: 3vw;
    margin-top: 0vw;
}

.circleButton__topActiveMobile {
    position: absolute;
    transition: all 2s;
    margin-top: -62vw;
    margin-left: 35vw;
}

.circleButton__bottomActiveMobile {
    position: absolute;
    transition: all 2s;
    margin-top: 61vw;
    margin-left: 34vw;
}