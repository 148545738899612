.innerTextMobile {
    position: absolute;
    min-height: 40vw;
    max-height: 40vw;
    min-width: 4vw;
    max-width: 40vw;
    margin-left: 18vw;
    margin-top: -25vw;
    border-radius: 50%;
    padding: 4vw;
    /* animation: innerTextRotation 10s infinite linear;
    transition: rotate 1s ease-out; */
    animation: innerTextRotation 15s linear infinite;
    transition: 3s ease-out;
    background-image: linear-gradient(#C32376, black);
    z-index: 10;
}

.innerTextMobile:hover {
    animation-play-state: paused;
    transform: rotate(-.1turn);
    box-shadow: inset 0 0 0 100vmax rgba(0, 0, 0, 1);
}

@keyframes innerTextRotation {
    to {rotate: -1turn}
}