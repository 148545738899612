@font-face {
  font-family: "SoftTechno";
  src: local("SoftTechno"),
    url("./assets/fonts/SoftTechno.otf") format("opentype");
}

@font-face {
  font-family: "ScrawlStorm";
  src: local("ScrawlStorm"),
    url("./assets/fonts/ScrawlStorm.otf") format("opentype");
}

@font-face {
  font-family: "CierraText";
  src: local("CierraText"),
    url("./assets/fonts/CierraText.ttf") format("opentype");
}

body {
    max-width: 100%;
    overflow-x: hidden;
  background-color: black;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
