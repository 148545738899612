.loadingBlock {
    position: absolute;
    left: 0;
    min-width: 100%;
    min-height: 100%;
    max-width: 100%;
    max-height: 200%;
    background-color: black;
    z-index: 20;
  
   animation-name: fadingOut;
   animation-duration: 2s;
   animation-iteration-count: 1;
   animation-timing-function: ease-out; 
   animation-fill-mode: forwards;
   animation-delay: 4s;
  }
  
  @keyframes fadingOut {
    from { opacity: 100}
    to { opacity: 0 }
  }

  .fadeImageIn {
    position: absolute;

    z-index: 25;
  }

  .loadingCircleImage {
    position: absolute;
    width: 60vw;

    left: 20vw;
    top: 15vw;

    animation: rotation 5s infinite linear;
  }

  @keyframes rotation {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(359deg);
    }
}

.loadingFocalFadingC {
  position: absolute;
  left: 44vw;
  top: 25vw;
  opacity: 0.25;

  font-family: 'SoftTechno';
  color: white;
  font-size: 40vw;

  margin: 0;
  animation-name: fadingInOut;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out; 
  animation-fill-mode: linear;
}

@keyframes fadingInOut {
  0% { opacity: 0.25}
  50% { opacity: 1 }
  100% {opacity: 0.25}
}