.backButton {
    position: absolute;
    left: 2vw;
    top: 2vw;

    margin: 0vw 1vw 0 1vw;

    padding: 0 1vw 0 1vw;

    color: #ebebeb;
    font-family: "CierraText";
    font-weight: 900;
    text-align: center;
    font-size: 3vw;
    text-decoration: underline;
}

.bottomButton {
    left: 22vw;
}

.headerText {
    margin: 0;

    color: #ebebeb;
    font-family: "SoftTechno";
    text-align: center;
    font-size: 12vw;
}

.headerSubText {
    margin: -1vw 10vw 0 10vw;

    padding: 0 5vw 0 5vw;

    border-left: solid #ebebeb 0.5vw;
    border-right: solid #ebebeb 0.5vw;

    color: #ebebeb;
    font-family: "CierraText";
    text-align: center;
    font-size: 4vw;
    font-weight: 900;
}

.footerText {
    margin: 5vw 5vw 5vw 5vw;

    color: #ebebeb;
    font-family: "CierraText";
    text-align: center;
    font-size: 3vw;
    font-weight: 900;
}


/* project visualizer specific elements */
.visualizerRow {
    display: flex;
}

.timeline {
    flex: 10%;

    margin: 4vw 0 0 10vw;

    height: 85vw;

    border-left: solid white 0.5vw;
}

.bubbles {
    flex: 90%;
}

.timelineYearTickRow {
    display: flex;

    margin: 5vw 0 5vw -8vw;
}

.timelineYear {
    margin: 0;

    color: #ebebeb;
    font-family: "ScrawlStorm";
    font-size: 6vw;
    text-align: right;
}

.timelineTick {
    position: absolute;
    left: 10vw;
    /* margin: 2.5vw 0 0 1vw; */
    margin-top: 2.5vw;

    width: 3vw;

    border-top: solid #ebebeb 0.5vw;
}

/* blog specific elements */
.individualBlog {
    width: 37vw;
    height: 50vw;

    margin: 0 2vw 4vw 2vw;

    border: 0.5vw solid #ebebeb;
}

.blogPreviewImage {
    width: 30vw;

    margin: 2vw 0 0 3.5vw;
}

.blogFullImage {
    width: 60vw;

    margin: 2vw 0 0 20vw;
}

.blogList {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    margin-top: 10vw;
}

.blogTitle {
    margin: 1vw 0 0 0;

    color: #ebebeb;
    font-family: "CierraText";
    font-weight: 900;
    font-size: 5vw;
    text-align: center;
}

.activeBlog {
    margin-top: 6vw;
}

.activeBlogImgSubtext {
    margin: 1vw 20vw 0 20vw;

    color: #ebebeb;
    font-family: "CierraText";
    font-weight: 900;
    font-size: 2.5vw;
    text-align: center;
}

.blogParagraph {
    margin: 5vw 5vw 0 5vw;

    color: #ebebeb;
    font-family: "CierraText";
    font-weight: 900;
    font-size: 4vw;
    text-align: left;
}