.drawing {
    margin-top: 1vw;
    margin-left: 5.5vw;
}

.quadDesign {
    min-height: 43.5vw;
    max-height: 43.5vw;
    min-width: 43.5vw;
    max-width: 43.5vw;
    margin-left: -0.75vw;
    margin-top: -0.75vw;
    position: absolute;
    z-index: 5;
}

.quadDesignColor {
    min-height: 43.5vw;
    max-height: 43.5vw;
    min-width: 43.5vw;
    max-width: 43.5vw;
    margin-left: -0.75vw;
    margin-top: -0.75vw;
    position: absolute;
    z-index: 4;
}


.binaryImage {
    min-height: 42vw;
    max-height: 42vw;
    min-width: 42vw;
    max-width: 42vw;
    border-radius: 50%;
    position: absolute;
    animation: rotation 60s infinite linear;
}

@keyframes rotation {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(359deg);
    }
}

.edgeCovers {
    min-height: 41.8vw;
    max-height: 41.8vw;
    min-width: 41.8vw;
    max-width: 41.8vw;
    position: absolute;
    margin-left: 0.08vw;
    margin-top: 0.155vw;
}

/* 3 classes for transitioning to life */
.edgeEngToLife {
    animation: rotationEngToLife  1s 1 linear;
    transform: rotate(0deg);
}
@keyframes rotationEngToLife  {
    from {
        transform: rotate(179deg);
    }

    to {
        transform: rotate(0deg);
    }
}

.edgeDesToLife {
    animation: rotationDesToLife  1s 1 linear;
    transform: rotate(0deg);
}
@keyframes rotationDesToLife  {
    from {
        transform: rotate(270deg);
    }

    to {
        transform: rotate(0deg);
    }
}

.edgeEntrepToLife {
    animation: rotationEntrepToLife  1s 1 linear;
    transform: rotate(0deg);
}
@keyframes rotationEntrepToLife  {
    from {
        transform: rotate(89deg);
    }

    to {
        transform: rotate(-0eg);
    }
}

/* 3 classes for transitioning to Des */
.edgeEngToDes {
    animation: rotationEngToDes  1s 1 linear;
    transform: rotate(-90deg);
}
@keyframes rotationEngToDes  {
    from {
        transform: rotate(179deg);
    }

    to {
        transform: rotate(-90deg);
    }
}

.edgeLifeToDes {
    animation: rotationLifeToDes  1s 1 linear;
    transform: rotate(-90deg);
}
@keyframes rotationLifeToDes  {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(-90deg);
    }
}

.edgeEntrepToDes {
    animation: rotationEntrepToDes  1s 1 linear;
    transform: rotate(-90deg);
}
@keyframes rotationEntrepToDes  {
    from {
        transform: rotate(89deg);
    }

    to {
        transform: rotate(-90deg);
    }
}

/* 3 classes for transitioning to Eng */
.edgeLifeToEng {
    animation: rotationLifeToEng  1s 1 linear;
    transform: rotate(-179deg);
}
@keyframes rotationLifeToEng  {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(-179deg);
    }
}

.edgeDesToEng {
    animation: rotationDesToEng  1s 1 linear;
    transform: rotate(-179deg);
}
@keyframes rotationDesToEng  {
    from {
        transform: rotate(-89deg);
    }

    to {
        transform: rotate(-179deg);
    }
}

.edgeEntrepToEng {
    animation: rotationEntrepToEng  1s 1 linear;
    transform: rotate(-179deg);
}
@keyframes rotationEntrepToEng  {
    from {
        transform: rotate(89deg);
    }

    to {
        transform: rotate(-179deg);
    }
}

/* 3 classes for transitioning to Entrep */
.edgeEngToEntrep {
    animation: rotationEngToEntrep  1s 1 linear;
    transform: rotate(-269deg);
}
@keyframes rotationEngToEntrep  {
    from {
        transform: rotate(-179deg);
    }

    to {
        transform: rotate(-269deg);
    }
}

.edgeDesToEntrep {
    animation: rotationDesToEntrep  1s 1 linear;
    transform: rotate(-269deg);
}
@keyframes rotationDesToEntrep  {
    from {
        transform: rotate(-89deg);
    }

    to {
        transform: rotate(-269deg);
    }
}

.edgeLifeToEntrep {
    animation: rotationLifeToEntrep  1s 1 linear;
    transform: rotate(-269deg);
}
@keyframes rotationLifeToEntrep  {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(-269deg);
    }
}

.innerCircleButton {
    min-height: 4vw;
    max-height: 4vw;
    min-width: 4vw;
    max-width: 4vw;
    margin-top: 1vw;
    margin-left: 1vw;
    border-radius: 50%;
    position: absolute;
    transition: all 2s;
    z-index: 15;
}

.innerCircleButtonActive {
    min-height: 6vw;
    max-height: 6vw;
    min-width: 6vw;
    max-width: 6vw;
    margin-top: 1vw;
    margin-left: 1vw;
    border-radius: 50%;
    position: absolute;
    transition: all 2s;
    z-index: 15;
}

.circleButton {
    border: solid #ebebeb 2px;
    min-height: 6vw;
    max-height: 6vw;
    min-width: 6vw;
    max-width: 6vw;
    border-radius: 50%;
    position: absolute;
    transition: all 2s;
    z-index: 15;
}

.circleButtonActive {
    border: solid black 2px;
    background-color: #efefef;
    min-height: 8vw;
    max-height: 8vw;
    min-width: 8vw;
    max-width: 8vw;
    border-radius: 50%;
    position: absolute;
    transition: all 2s;
    z-index: 15;
}

.innerCircleButton__left {
    background-color: #C32376;
}

.innerCircleButton__right {
    background-color: black;
}

.innerCircleButton__top {
    background-color: #ffc6d3;
}

.innerCircleButton__bottom {
    background-color: #df46b7;
}

.circleButton__left {
    transition: all 2s;
    margin-left: 3vw;
    margin-top: 18vw;
}

.circleButton__right {
    transition: all 2s;
    margin-left: 33vw;
    margin-top: 18vw;
}

.circleButton__top {
    transition: all 2s;
    margin-top: 2.5vw;
    margin-left: 18vw;
}

.circleButton__bottom {
    transition: all 2s;
    margin-top: 33vw;
    margin-left: 18vw;
}

.circleButton__rightActive {
    transition: all 2s;
    margin-left: 31vw;
    margin-top: 17vw;
}

.circleButton__leftActive {
    transition: all 2s;
    margin-left: 2.5vw;
    margin-top: 17vw;
}

.circleButton__topActive {
    transition: all 2s;
    margin-top: 2.5vw;
    margin-left: 17vw;
}

.circleButton__bottomActive {
    transition: all 2s;
    margin-top: 31vw;
    margin-left: 17vw;
}