.projectSlider {
    margin-bottom: 20vw;
}

.projectSliderMobile {
    margin-bottom: 23vw;
    margin-top: -4vw;
}

.projectList {
    display: flex;
    width: 70%;
    margin-left: 15%;
    overflow-x: scroll;
    overflow-y: hidden;
}

.projectListMobile {
    margin-top: 5vw;
    display: flex;
    width: 86%;
    margin-left: 7%;
    overflow-x: scroll;
    overflow-y: hidden;
}

.project {
    position: relative;
    background-color: black;
    color: #ebebeb;
    border: solid #ebebeb 0.5vw;
    height: 30vw;
    width: 5vw;
    z-index: 1;
}


.activeProject {
    position: relative;
    background-color: black;
    color: #ebebeb;
    border: solid #ebebeb 0.5vw;
    height: 30vw;
    z-index: 10;
    min-width: 48vw;
    max-width: 48vw;
}


.projectMobile {
    position: relative;
    background-color: black;
    color: #ebebeb;
    border: solid #ebebeb 0.5vw;
    height: 40vw;
    width: 6vw;
    z-index: 1;
}


.activeProjectMobile {
    position: relative;
    background-color: black;
    color: #ebebeb;
    border: solid #ebebeb 0.5vw;
    height: 40vw;
    z-index: 10;
    min-width: 58vw;
    max-width: 58vw;
}

.dottedTrack {
    border-top: dotted #ebebeb 1.2vw;
    z-index: -1;
    position: relative;
    margin-top: -17.25vw;
}

.dottedTrackMobile {
    border-top: dotted #ebebeb 1.2vw;
    z-index: -1;
    position: relative;
    margin-top: -19.25vw;
}

.projectTitle {
    writing-mode: vertical-lr;
    font-family: "SoftTechno";
    text-transform: uppercase;
    font-size: 5.5vw;
    margin-left: 0px;
    margin-top: 1vw;
}

.activeProjectTitle {
    color: black;
    font-family: "SoftTechno";
    text-transform: uppercase;
    font-size: 6vw;
    margin-top: 0;
    margin-bottom: 0;
    width: fit-content;
    background-color: #ebebeb;
    border-bottom-right-radius: 2.5vw;
    z-index: 1;
    border :0.45vw #C32376 solid;
    margin-left: 0.25vw;
    padding-right: 2vw;
    padding-left: 1vw;
}

.projectTitleMobile {
    writing-mode: vertical-lr;
    font-family: "SoftTechno";
    text-transform: uppercase;
    font-size: 7.5vw;
    margin-left: -0.3vw;
    margin-top: 1vw;
}

.activeProjectTitleMobile {
    color: black;
    font-family: "SoftTechno";
    text-transform: uppercase;
    font-size: 8vw;
    margin-top: 0vw;
    margin-bottom: 0vw;
    width: fit-content;
    background-color: #ebebeb;
    border-bottom-right-radius: 2.5vw;
    z-index: 1;
    border :0.45vw #C32376 solid;
    margin-left: 0.25vw;
    padding-right: 2vw;
    padding-left: 1vw;
}

.activeTitleDiv {
    padding-top: 0.25vw;
    padding-right: 1vw;
    padding-bottom: 1vw;
    background-color: #ebebeb;
    width: fit-content;
    border-bottom-right-radius: 3vw;
}

.activeProjectContent {
    margin-left: 1vw;
    font-family: "SoftTechno";
}

.activeDescription {
    margin-top: -2.8vw;
    margin-bottom: 0;
    font-size: 9vw;
}

.activeDescriptionMobile {
    margin-top: -2.8vw;
    margin-bottom: 0;
    font-size: 11vw;
}

.moreDetails {
    flex: 70%;
    font-family: "ScrawlStorm";
    /* font-weight: 1000; */
    font-size: 5.5vw;
    line-height: 5vw;
    margin-top: 0.1vw;
    margin-bottom: 0vw;
    text-align: left;
    padding-left: 0.5vw;
    padding-bottom: 0;
}

.moreDetailsMobile {
    flex: 70%;
    font-family: "ScrawlStorm";
    /* font-weight: 1000; */
    font-size: 7.5vw;
    line-height: 7vw;
    margin-top: 0.1vw;
    margin-bottom: 0vw;
    text-align: left;
    padding-left: 0.5vw;
}

.activeBottomDiv {
    display: flex;
}

.coreTags {
    display: flex;
    position: relative;
    float: right;
    margin-top: -5.9vw;
    margin-bottom: 0;
}

.coreTagsMobile {
    display: flex;
    position: relative;
    float: right;
    margin-top: -7vw;
    margin-bottom: 0;
}

.engTag{
    border: black solid 0.5vw;
    background-color: #C32376;
    min-width: 3vw;
    max-width: 3vw;
    min-height: 4.8vw;
    max-height: 4.8vw;
    margin-bottom: 0;
}

.entrepTag{
    border: black solid 0.5vw;
    background-color: #df46b7;
    min-width: 3vw;
    max-width: 3vw;
    min-height: 4.8vw;
    max-height: 4.8vw;
    margin-bottom: 0;
}

.designTag{
    border: black solid 0.5vw;
    background-color: #ffc6d3;
    min-width: 3vw;
    max-width: 3vw;
    min-height: 4.8vw;
    max-height: 4.8vw;
    margin-bottom: 0;
}

.iconTag {
    width: 5vw;

    margin-top: -10vw;
}