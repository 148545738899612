.biographyText {
    margin: 2vw 0vw 2vw 10vw;
    width: 70vw;

    padding: 2vw 5vw 2vw 5vw;
    background-color: black;
    border: 0.5vw solid #ebebeb;

    font-family: "CierraText";
    font-size: 4vw;
    font-weight: 900;
    color: #ebebeb;
    line-height: 4.2vw;
    text-align: center;
}